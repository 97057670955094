@import '~antd/dist/antd.css';

.App {
  text-align: center;
  background-color: "white;";
  flex: 1;
  height: "100%";
  width: "100%";
  display: "flex";
  flex-direction: column;
}

.Page {
    width: "100%";
    height: "100%";
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: "white";
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: "black";
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.ipf-appbar .ant-tabs-bar {
    font-size: 24px;
    border-bottom: 1px solid black; 
}

.h1 {
    margin-top: 40;
}