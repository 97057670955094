h1 {
  font-weight: "700";
  font-family: IBM Plex Sans;
  font-size: 20px;
}

h2 {
  font-weight: "700";
  font-family: IBM Plex Sans;
  font-size: 30px;
}
